.wd-100 {
  width: 100%;
}

.px-0-1p {
  padding-left: 3px !important;
  padding-right: 0 !important;
}

.dms-primary-color {
  background-color: #0061f0;
  color: #0061f0;
}

/* top bar/navigation bar css */
.nav-profile-img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.nav-bell-icon {
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 1rem;
}

.app-btn {
  border: none;
  border-radius: 4px;
  padding: 0.375rem 0.75rem;
  min-height: 30px;
  min-width: 70px;
  cursor: pointer;
}

.nav-profile > button {
  text-align: start;
  color: white;
  background-color: #0061f0 !important;
  border: none;
}

.leftbar-nav-profile > button {
  color: black;
  background-color: white !important;
  padding: 0;
}

.nav-profile > button:active {
  color: white !important;
}

.leftbar-nav-profile > button:hover {
  color: black !important;
}

.leftbar-nav-profile .show {
  color: black !important;
}

.nav-profile .dropdown-toggle::after {
  display: none !important;
}

.nav-profile .dropdown-item {
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
}

.nav-profile-dropdown {
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px;
}

.leftbar-nav-profile-dropdown {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px;
}

.nav-profile-dropdown .dropdown-toggle {
  display: flex;
  align-items: center;
}

/* top bar/navigation bar css ENDS */

/* top bar css */
.topbar-container {
  padding: 0.5rem 1.25rem;
  display: flex;
  gap: 0.5em;
  align-items: center;
  color: white;
  height: 4rem;
}

.logo-text {
  font-size: 2.25rem;
  margin-top: 0.25rem;
}

.logo-small-text {
  font-size: 0.75rem;
  font-weight: 500;
}

.nav-name-designation {
  max-width: 300px;
}

.leftbar-nav-name-designation {
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-name-text {
  font-size: 1rem;
  font-weight: 500;
}

.leftbar-profile-name-text {
  font-size: 0.75rem;
  font-weight: 500;
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.profile-designation-text {
  font-size: 0.75rem;
  font-weight: 500;
  opacity: 0.8;
  max-width: 250px;
}

.leftbar-profile-designation-text {
  font-size: 0.5rem;
  opacity: 0.8;
  max-width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.profile-workstation-text {
  font-size: 0.75rem;
  font-weight: 500;
  opacity: 0.8;
  max-width: 200px;
}

.leftbar-profile-workstation-text {
  font-size: 0.5rem;
  opacity: 0.8;
  max-width: 200px;
  min-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.dropdown-item:hover {
  background-color: #e7f1ff !important;
}

.language-toggle-text {
  color: #212529;
  font-size: 1rem;
}

.leftbar-dropdown-item {
  font-size: 0.75rem;
}

/* top bar css ENDS */
/* left bar css */
.leftbar-wrapper {
  height: calc(100vh - 8rem);
  padding: 8px 0;
  max-height: 100%;
  position: fixed;
  top: 4rem;
  left: 0;
  background: #fff;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
  overflow-y: auto;
  scrollbar-color: hsl(215.75, 100%, 50.06%) hsl(215, 100%, 95.29%);
  scrollbar-width: thin;
  z-index: 1000;
  transition: all 0.2s ease-out;
}

.leftbar-hide {
  left: -100%;
}

.leftbar-show {
  left: 0;
}

.width-leftbar {
  width: 20.5rem;
}

.language-toggle-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 20.5rem;
  background-color: white;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  transition: all 0.2s ease-out;
}

.language-toggle-container-hide {
  left: -100%;
}

.language-toggle-container-show {
  left: 0;
}

.accordion-button:not(.collapsed) {
  color: #0061f0 !important;
  font-weight: 700;
  border-style: none !important;
}

.accordion-item,
.accordion-header {
  outline: none !important;
}

.accordion {
  --bs-accordion-border-color: transparent !important;
  --bs-accordion-btn-icon-width: 1rem !important;
  color: #212529 !important;
}

.accordion-button {
  background-color: transparent !important;
  padding: 0px !important;
}

.accordion-body {
  padding: 0 !important;
}

.active-menu {
  background-color: #f0f0f0 !important;
  color: #212529 !important;
  font-weight: 600 !important;
}

.nav {
  gap: 0.25rem !important;
}

.nav-link {
  color: #212529 !important;
}

.leftbar_icon_size {
  width: 1.125rem;
}

.text-small {
  font-size: 0.5rem;
  color: #6c757d;
}

.dropdown-btn {
  justify-content: space-between !important;
  background-color: white !important;
  border: 1px solid #ced4da !important;
  align-items: center !important;
  border-radius: 4px !important;
  text-align: start !important;
  color: black !important;
  width: 17.5 !important;
  display: flex !important;
  padding: 0.5rem !important;
}

/* leftbar css ends */
.toaster {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0.75rem;
  z-index: 1;
}

.backdrop--loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

/* Bar chart starts */

.bar-chart {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.bar-chart__label {
  font-weight: bold;
  font-size: 13px;
}

.bar-chart__value {
  display: flex;
}

/* Bar chart ends */

.modal-header > .btn-close {
  display: none;
}

.react-date-picker__calendar {
  z-index: 10;
}

.react-date-picker_input {
  display: block;
  width: 100%;
  padding: 0.275rem 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.react-date-picker__wrapper {
  border: none !important;
}

.react-date-picker__button {
  padding: 1.5px;
}

.file-upload__input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: 1px solid var(--bs-border-color);
}

.file-upload__input:focus {
  box-shadow: none !important;
}

.file-upload__button {
  border: 1px solid #0061f0;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  max-width: 200px;
}

.wd-30 {
  width: 30%;
}

/* KebabMenu starts */

.kebabmenu > button {
  background-color: transparent !important;
}

/* KebabMenu ends */

.redirect-page__container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
}

.redirect-page__first-btn {
  background-color: transparent;
  border: 1px solid #0061f0;
  color: #0061f0;
  font-weight: 700;
  padding: 0.5rem 1rem;
}

.redirect-page__second-btn {
  background-color: #0061f0;
  font-weight: 700;
  color: white;
  padding: 0.5rem 1rem;
}

.notification-div {
  position: relative;
  display: inline-block;
}

.notification-badge {
  position: absolute;
  top: -5px;
  /* Adjust this value to fine-tune the vertical position */
  right: 5px;
  /* Adjust this value to fine-tune the horizontal position */
  background-color: red;
  color: white;
  font-size: 12px;
  font-weight: bold;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-dropdown {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 2rem !important;
}

.table-responsive th {
  cursor: pointer;
  white-space: nowrap;
}

.table-responsive th,
.table-responsive td {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

.report__filter-clear__button {
  border: 1px solid #0d6efd;
  color: #0d6efd;
  background-color: white;
  font-weight: 500;
  font-size: 20;
}

.app__dropdown-btn .dropdown-toggle {
  border-radius: 4px;
  padding: 0.375rem 0.75rem;
  min-height: 30px;
  min-width: 70px;
  color: #0d6efd;
  background-color: transparent !important;
}

.app__dropdown-btn .dropdown-toggle:hover,
.app__dropdown-btn .dropdown-toggle:focus,
.app__dropdown-btn .dropdown-toggle:active {
  background-color: transparent !important;
  color: #0d6efd !important;
}

.app_dropdown_disable-btn {
  background-color: #b2b5b8 !important;
  color: white !important;
  cursor: not-allowed !important;
}

.disable-btn {
  background-color: #dee2e6 !important;
  color: rgb(138, 121, 121) !important;
  cursor: not-allowed !important;
}

.filter-btn {
  background-color: #0061f0;
  color: white !important;
  cursor: pointer;
}

.app__card-container:hover {
  transform: scale(1.04);
  transition: 0.6s;
}

.app__card-body {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.app__card-body-text {
  font-size: 20px;
  text-decoration: none;
  font-weight: 600;
  margin: 0.5rem 0;
}

/* Style for server error page start*/
.server_error_main {
  width: 100vw;
  height: 100vh;
  min-height: 450px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.server_error_wrap {
  width: 300px;
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
}

/* Styling for lines and animation */
.server_error_line {
  position: absolute;
  left: 0;
  top: 33%;
  width: 100%;
}

.server_error_line-1 {
  animation: anim-line1 5s 0s ease forwards;
}

.server_error_line-2 {
  animation: anim-line2 5s 1.2s ease forwards;
}

.server_error_line-3 {
  animation: anim-line3 5s 2.4s ease forwards;
}

.server_error_ball {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #000;
  color: #fff;
  text-align: center;
  line-height: 40px;
  font-size: 30px;
  font-weight: bold;
  font-family: 'Source Code Pro', monospace;
  z-index: 2;
  animation: anim-ball 5s ease forwards;
}

/* Hand styles */
.server_error_hand {
  position: absolute;
  top: 50%;
  width: 100%;
  transform-origin: 50% 0;
}

.server_error_hand-left {
  left: 6px;
  animation: anim-hand-left 5s 0s ease forwards;
}

.server_error_hand-right {
  right: 6px;
  animation: anim-hand-right 5s 0.5s ease forwards;
}

.server_error_hand-part {
  height: 2px;
  width: 30px;
  background: #000;
  position: absolute;
}

.server_error_part-bottom {
  top: -10px;
  left: 0;
  height: 20px;
  border-radius: 7px;
  background: #ccc;
  border-bottom: 4px solid #000;
}

.server_error_part-middle {
  left: 26px;
  top: -10px;
  transform: rotate(-40deg);
}

.server_error_part-top {
  left: 45px;
  top: -32px;
  transform: rotate(-60deg);
}

.server_error_server {
  width: 150px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 3px solid #1c1000;
  padding-bottom: 2%;
  background: #1c1000;
  position: relative;
}

.server_error_eye {
  position: absolute;
  top: -12px;
  width: 20px;
  height: 25px;
  border: 1px solid #000;
  box-shadow: 0 0 0 2px #ccc;
  background: #fff;
  border-radius: 50%;
}

.server_error_eye-left {
  left: 30px;
}

.server_error_eye-right {
  right: 30px;
}

.server_error_eye span {
  display: block;
  width: 50%;
  height: 50%;
  border: 1px solid;
  border-radius: 50%;
  background: #000;
  position: absolute;
  left: 0;
  top: 1px;
  animation: anim-eyes 8s ease forwards;
}

.server_error_block {
  border: 1px solid #000;
  height: 11%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2) inset;
  background: #555;
}

.server_error_light {
  width: calc(100% - 4px);
  height: 12px;
  border-bottom: 5px solid;
  background: linear-gradient(
    to right,
    #2579ba 0%,
    #0089f2 26%,
    #214db2 50%,
    #010023 51%,
    #0d0e19 100%
  );
}

.server_error_bottom-block {
  height: 30%;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #555;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2) inset;
  overflow: hidden;
  position: relative;
}

.server_error_bottom-light {
  width: 2vw;
  height: 2vw;
  min-width: 14px;
  min-height: 14px;
  border-radius: 4px;
  background: #fff;
  border: 2px solid #ccc;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2) inset;
  z-index: 2;
}

.server_error_bottom-line {
  width: calc(100% - 4px);
  height: 3px;
  background: #fff;
  position: absolute;
  border-bottom: 2px solid #ccc;
}

/* Error text */
.server_error_code-error {
  width: 60%;
  min-width: 320px;
  text-align: center;
}

.server_error_code-error h1 {
  font-family: 'Source Code Pro', monospace;
  font-size: 4vw;
  font-weight: bold;
  margin: 1vw 0;
}

/* Animations */
@keyframes anim-ball {
  20% {
    transform: rotate(180deg);
  }

  40% {
    transform: rotate(0deg);
  }

  60% {
    transform: rotate(180deg);
  }

  80% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-90deg);
  }
}

@keyframes anim-line1 {
  20% {
    transform: rotate(180deg);
  }

  40% {
    transform: rotate(0deg);
  }

  60% {
    transform: rotate(180deg);
  }

  80% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(90deg) translateY(40px);
  }
}

@keyframes anim-line2 {
  20% {
    transform: rotate(180deg);
  }

  40% {
    transform: rotate(0deg);
  }

  60% {
    transform: rotate(180deg);
  }

  80% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(90deg) translateY(40px);
  }
}

@keyframes anim-line3 {
  20% {
    transform: rotate(180deg);
  }

  40% {
    transform: rotate(0deg);
  }

  60% {
    transform: rotate(180deg);
  }

  80% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(90deg) translateY(40px);
  }
}

@keyframes anim-hand-left {
  0%,
  20% {
    transform: rotate(-10deg);
  }

  80%,
  100% {
    transform: rotate(0deg);
  }
}

@keyframes anim-hand-right {
  0%,
  20% {
    transform: rotate(10deg);
  }

  80%,
  100% {
    transform: rotate(0deg);
  }
}

@keyframes anim-eyes {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-2px);
  }
}

/* Style for server error page end*/

/* dashboard card starts */
.dashboard-card {
  padding: 1rem 0;
  background-color: white;
  box-shadow: 1px 1px 3px 0 #00000040;
  border-radius: 3px;
}

.dashboard-card__title {
  padding: 0 1rem;
  font-size: 1.25rem;
  font-weight: bold;
}

.dashboard-card__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 25rem;
  padding: 0 1rem;
}

/* dashboard card ends */

.previous-page {
  color: blue;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
}

/* App Spinner */
.loader {
  width: 50px;
  --b: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 1px;
  background: conic-gradient(#0000 10%, white) content-box;
  -webkit-mask: repeating-conic-gradient(#0000 0deg, #000 1deg 20deg, #0000 21deg 36deg),
    radial-gradient(farthest-side, #0000 calc(100% - var(--b) - 1px), #000 calc(100% - var(--b)));
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: l4 1s infinite steps(10);
}
@keyframes l4 {
  to {
    transform: rotate(1turn);
  }
}

.duty-change:hover {
  background-color: #f2f5ff;
}

.duty-change-radio .form-check-input {
  border: 2px solid #b4becd;
}
