@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Bengali:wght@400;500;600;700;800&display=swap');

* {
  box-sizing: border-box;
}
html {
  overflow-y: scroll;
}
body {
  margin: 0;
  padding: 0 !important;
  font-family: 'Noto Sans Bengali', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #222222;
  background-color: #f8f9fa;
}

.dropdown-toggle {
  border: none;
}
.container-mx-width {
  max-width: 1560px;
}

.page-container {
  max-width: calc(100% - 23.75rem);
  margin-left: auto;
  width: 100%;
  margin-right: 1.25rem;
}

.show-page-container {
  max-width: 100%;
  padding: 0 1.25rem;
}

.bg-blue-100 {
  background-color: #e7f1ff;
}

.font-grey {
  color: #6c757d;
}

.mx-32p {
  margin: 0 32px;
}

.px-32p {
  padding: 0 32px !important;
}

.py-20p {
  padding: 20px 0;
}

.cust-g-1 {
  gap: 4px;
}
.cust-g-2 {
  gap: 8px;
}
.cust-g-3 {
  gap: 12px;
}
.cust-g-4 {
  gap: 16px;
}

.pointer {
  cursor: pointer;
}

.h-60p {
  height: 60px;
}

.h-86p {
  height: 86px;
}

.min-h-60v {
  min-height: 60vh;
}

.min-h-80v {
  min-height: 80vh;
}

/* DMS all colors */

.dms-bg-100 {
  color: #d6d6d6;
}

.dms-bg-primary-50 {
  background-color: #e7f1ff;
}

.dms-bg-200 {
  color: #f8f9fa;
}

.dms-primary-50 {
  color: #e7f1ff;
}

.dms-primary-100 {
  color: #9ec5fe; /* secondary color */
}

.dms-bg-primary {
  background-color: #0061f0; /* main color */
}

.dms-primary-500 {
  color: #0d6efd;
}

.dms-primary {
  color: #0061f0; /* main color */
}

.dms-bg-disable {
  background-color: #dee2e6;
}

.dms-disable-100 {
  color: #dee2e6;
}

.dms-disable-200 {
  color: #d1d5db;
}

.dms-disable-300 {
  color: #ced4da;
}

.dms-disable-400 {
  color: #6c757d;
}

.dms-warning {
  color: #fd7e14; /* জমা দেয়নি */
}

.dms-success {
  color: #198754;
}

.dms-danger-100 {
  color: #dc3545;
}

.dms-danger-200 {
  color: #d82525; /* text */
}

.dms-info-50 {
  color: #fff3cd;
}

.dms-info-100 {
  color: #ffe5d0;
}

.dms-info-200 {
  color: #feb272; /*notice board*/
}

.dms-info-300 {
  color: #ffd148;
}

.dms-info-400 {
  color: #fd9843;
}

.dms-ongoing {
  color: #055160;
}

#root {
  white-space: pre-line;
}

.dms-status-ongoing-bg {
  background-color: #b6d4fe;
}

.dms-status-upcoming-bg {
  background-color: #ffcd29;
}

.dms-status-expired-bg {
  color: white;
  background-color: grey;
}

.dms-status-over-bg {
  color: #e0e0e0;
  background-color: #6c757d;
}

.kebabmenu-danger {
  color: red;
}

/* global buttons */
.dms-primary-btn {
  background-color: #0061f0;
  color: white;
  padding: 0.5rem 0.75rem;
  border: 1px solid;
  border-radius: 8px;
}

.dms-primary-btn:hover {
  background-color: rgba(0, 97, 240, 0.85); /* Primary color with opacity */
}
/* global buttons ENDS */

.react-calendar__month-view__days__day--weekend {
  color: black;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.filter-container {
  background-color: #e7f1ff;
  border-radius: 10px;
}

.app-filter_container {
  background-color: #e7f1ff;
  border-radius: 10px;
  margin: 0.75rem 0;
  padding: 1rem;
}

.app-report-filter_container {
  margin: 1rem 0;
  border-radius: 10px;
  background-color: #e7f1ff;
  padding: 1rem 1rem 2rem 1rem;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: #9ec5fe;
}

.dotted-line {
  border-top: 2px dotted #21252940;
  width: 100%;
  margin: 2rem 0;
}

/* custom buttons for DMS */
.primary-btn {
  background-color: #0061f0;
  color: white;
  padding: 0.375rem 0.75rem;
  border: 1px solid;
  border-radius: 8px;
}
.primary-btn:hover {
  background-color: #084298;
}
.secondary-btn {
  border: none;
  background-color: transparent;
  color: #0061f0;
  border: 1px solid #0061f0;
  border-radius: 8px;
  padding: 0.375rem 0.75rem;
}
.default-btn {
  border: 1px solid;
  border-color: #0061f0;
  background-color: transparent;
  color: #0061f0;
  border-radius: 4px;
  padding: 6px 12px;
}
.default-btn:hover {
  background-color: #e7f1ff;
  color: #0061f0;
}
.gray-btn {
  background-color: white;
  color: #0061f0;
  padding: 0.375rem 0.75rem;
  border: 1px solid;
  border-radius: 4px;
}
.gray-btn:hover {
  background-color: #6c757d;
  color: white;
}

.font-20 {
  font-size: 20px;
}

.font-bold {
  font-weight: 700;
}

.nav-item-padding {
  --bs-nav-link-padding-y: 0rem !important;
}

.wd-10 {
  width: 10%;
}

.wd-90 {
  width: 90%;
}

@media (max-width: 991px) {
  /* … */
  .page-container {
    max-width: 100%;
    padding: 0 1.25rem;
  }
}

/* table structure css */
.t-size {
  padding: 0.5rem 1.5rem !important;
}

.th-bg {
  background-color: #e6edf3 !important;
}

.custom-table > tbody > tr:nth-of-type(even) > * {
  background-color: #f0f3f5;
}

.custom-table > tbody > tr:nth-of-type(odd) > * {
  --bs-table-bg-type: white;
}

.custom-table td {
  text-align: start !important;
  vertical-align: middle !important;
  border: none !important;
}

.custom-table th {
  text-align: start !important;
  vertical-align: middle !important;
  border: none !important;
}
/* table structure css ENDS */

/* ISAS category wise color code*/
.isas-category {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  padding: 6px 12px;
  font-weight: bold;
}

.category-A {
  background: #d2f4ea;
  color: #1aa179;
  border: 1px solid #b5d5c8;
}

.category-B {
  background-color: #e0cffc;
  color: #6610f2;
  border: 1px solid #c2b5f9;
}

.category-C {
  background-color: #fff3cd;
  color: #cc9a06;
  border: 1px solid #e7d3b4;
}

.category-D {
  background-color: #ffe5d0;
  color: #ca6510;
  border: 1px solid #e6c2b5;
}

.category-E {
  background-color: #f8d7da;
  color: #dc3545;
  border: 1px solid #eeb8bb;
}

.category-U {
  background-color: #dde0e0;
  color: #696c6c;
  border: 1px solid #c3c4c4;
}
/* ISAS category wise color code ENDS*/

.error-page-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.border-1-primary {
  border: 1px solid #0d6efd !important;
}

.required-input:after {
  content: ' *';
  color: red;
}
